import React, { useEffect, useState } from "react";
import background from "./assets/background.webp";
import mi from "./assets/Vector.svg";
import { Linkedin } from "react-feather";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhoneVolume } from "@fortawesome/free-solid-svg-icons";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import { RiseLoader } from "react-spinners";

const App: React.FC = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const img = new Image();
    img.src = background;
    img.onload = () => setLoading(false);
  }, []);

  return (
    <div className="relative flex h-screen w-full items-center justify-center overflow-hidden bg-gray-200">
      {loading ? (
        <RiseLoader loading={loading} size={16} color="#4b5563" />
      ) : (
        <>
          <img
            className="animate-scaleAndRotate absolute left-0 top-0 h-full w-full object-cover"
            src={background}
            alt="background"
          />
          <div className="relative mx-4 w-full max-w-xl rounded-xl bg-white bg-opacity-60 p-6 shadow-lg md:p-10 lg:p-10">
            <div className="flex h-24 items-center justify-center">
              <img src={mi} className="h-16 opacity-60" alt="logo" />
            </div>
            <h1 className="font-cinzel py-4 text-center text-[28px] font-bold text-gray-600 md:text-4xl">
              Mohammed Imtiyaz
            </h1>
            <p className="font-marcellus px-4 text-center text-xl font-medium text-gray-800">
              Co-Founder & CEO at Workplay Studio Pvt Ltd
            </p>
            <div className="flex flex-col items-center justify-center gap-y-3 pt-10 text-[17px] sm:flex-row sm:gap-x-10 sm:gap-y-4 sm:text-lg">
              <a
                href="mailto:imtiyaz@workplay.digital"
                className="font-marcellus flex items-center text-gray-800"
              >
                <FontAwesomeIcon icon={faEnvelope} className="mr-2" /> -
                imtiyaz@workplay.digital
              </a>
              <a
                href="tel:+919742847450"
                className="font-marcellus flex items-center text-gray-800"
              >
                <FontAwesomeIcon icon={faPhoneVolume} className="mr-2" /> - +91
                9742847450
              </a>
            </div>

            <div className="font-marcellus flex items-center justify-center gap-x-4 pt-10 font-medium sm:pt-6">
              <a
                href={process.env.REACT_APP_LINKEDIN_URL}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center text-gray-800"
              >
                <Linkedin className="mr-2 h-4 w-4" /> LinkedIn
              </a>
              <a
                href={process.env.REACT_APP_WEBSITE_URL}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center text-gray-800"
              >
                <FontAwesomeIcon icon={faGlobe} className="mr-2 h-4 w-4" />{" "}
                Website
              </a>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default App;
